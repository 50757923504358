<template>
  <div class="auth-page-wrapper pt-5">
    <!-- auth page bg -->
    <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
      <div class="bg-overlay"></div>

      <div class="shape">
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 1440 120">
          <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
        </svg>
      </div>
      <canvas class="particles-js-canvas-el" width="1518" height="380" style="width: 100%; height: 100%"></canvas>
    </div>

    <!-- auth page content -->
    <div class="auth-page-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center mt-sm-5 mb-4 text-white-50">
              <div>
                <a href="#" class="d-inline-block auth-logo">
                  <img src="/assets/images/logo-light.png" alt="" width="200" />
                </a>
              </div>
              <p class="mt-3 fs-15 fw-medium">
              </p>
            </div>
          </div>
        </div>
        <!-- end row -->

        <div class="row justify-content-center">
          <div class="col-md-9">
            <div class="card mt-0">
              <div class="card-body p-4">
                <div class="text-center mt-2">
                  <h5 class="text-primary">Reset Password</h5>
                  <p class="text-muted">Silahkan atur ulang password anda.</p>
                </div>
                <div class="p-2 mt-4">
                  <form @submit.prevent="submit">


                    <div class="mb-3">
                      <label class="form-label">Email</label>
                      <input v-model="reset_password.email" type="text" class="form-control"
                        placeholder="Masukkan email" required />
                    </div>

                    <div class="mb-3">
                      <label class="form-label" for="password-input">Password</label>
                      <div class="position-relative auth-pass-inputgroup mb-3">
                        <input v-model="reset_password.password" :type="type.password" class="form-control pe-5"
                          placeholder="Enter password" required />
                        <button @click="addonPassword('password')"
                          class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                          type="button">
                          <i class="ri-eye-fill align-middle"></i>
                        </button>
                      </div>
                    </div>

                    <div class="mb-4">
                      <label class="form-label" for="password-input">Konfirmasi Password</label>
                      <div class="position-relative auth-pass-inputgroup mb-3">
                        <input v-model="reset_password.password_confirmation" :type="type.password_confirmation"
                          class="form-control pe-5" placeholder="Enter password" required />
                        <button @click="addonPassword('password_confirmation')"
                          class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                          type="button">
                          <i class="ri-eye-fill align-middle"></i>
                        </button>
                      </div>
                    </div>

                    <div class="mt-4">
                      <button :disabled="load" class="btn btn-success w-100" type="submit">
                        <i class="bx font-size-16 align-middle me-2" :class="{
                          'bx-loader bx-spin': load,
                          'bx-reset': !load,
                        }"></i>
                        Reset Password
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div class="mt-4 text-center">
              <p class="mb-0">
                Ingat password akun ?
                <router-link :to="{ name: 'login' }" class="fw-semibold text-primary text-decoration-underline">
                  Login disini
                </router-link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <p class="mb-0 text-muted">&copy;2022 - {{  year  }} {{  title  }}</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import axios from 'axios';
import { endpoint } from '../../host';
import errorService from '../../services/error-service';
import httpService from '../../services/http-service';
import globalService from '../../services/global-service';
import Swal from 'sweetalert2';
import moment from 'moment';

export default {
  created() {
    let query = this.$route.query;
    this.reset_password.token = query.token;
    var scripts = [
      "assets/libs/particles.js/particles.js",
      "assets/js/pages/particles.app.js",
    ];
    scripts.forEach((script) => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.head.appendChild(tag);
    });
  },
  data() {
    return {
      title: process.env.VUE_APP_TITLE,
      password_type: "password",
      share_period: null,
      load: false,
      year: moment().year(),
      price_total: 0,
      reset_password: {
        email: null,
        password: null,
        password_confirmation: null,
        token: null,
      },
      type: {
        password: 'password',
        password_confirmation: 'password',
      },
    };
  },
  methods: {
    async submit() {
      try {
        this.load = true;
        let r = await axios.post(endpoint.password + "/reset", this.reset_password);
        this.load = false;
        if (r.status == 200) {
          Swal.fire({
            title: "Berhasil",
            text: "password berhasil diganti, silahkan login menggunakan password baru",
            icon: "success",
            showCancelButton: true,
            confirmButtonColor: "#405189",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ya, login",
            cancelButtonText: "Tidak, lain kali",
            buttonsStyling: true,
          }).then((isConfirm) => {
            if (isConfirm.value === true) {
              localStorage.clear();
              this.$router.push({
                name: "login",
              });
            }
          });
        }
      } catch (error) {
        this.load = false;
        errorService.displayError(error);
      }
    },
    addonPassword(a) {
      if (a == 'password') {
        this.type.password == "password"
          ? (this.type.password = "text")
          : (this.type.password = "password");
      } else if (a == 'password_confirmation') {
        this.type.password_confirmation == "password"
          ? (this.type.password_confirmation = "text")
          : (this.type.password_confirmation = "password");
      }
    },

  },
};
</script>

<style>
</style>